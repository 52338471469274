<template>
  <v-snackbar
    v-model="model"
    :color="$store.getters['toast/getColor']"
    :timeout="$store.getters['toast/getTimeout']"
    :shaped="true"
    :bottom="true"
    :left="true">
    <v-row no-gutters justify="space-between" align="center">
      <div class="MontserratMedium" v-html="$store.getters['toast/getText']" />

      <v-btn icon @click="$store.commit('toast/hideToast')">
        <v-icon flat >fas fa-times-circle</v-icon>
      </v-btn>
    </v-row>

  </v-snackbar>
</template>

<script>
  export default {

    name: "Toast",

    computed: {
      model: {
        get() {
          return this.$store.getters['toast/isVisible']
        },
        set(val) {
          this.$store.commit('toast/setVisible', val)
        }
      }
    }
  }
</script>

<style scoped>

</style>